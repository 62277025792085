import React, { FC } from "react";
import Layout from "../components/Layout/Layout";
import DevelopmentMain from "../components/DevelopmentUiComponent/DevelopmentMain";
import { SEO } from "../components/seo";
import { graphql } from "gatsby";
import headerimg from "../images/shot_01.png";

type Props = {
  data: any;
};

const Development: FC<Props> = ({ data }) => {
  return (
    <Layout location="/blog">
      <SEO
        title="Cloud-Native Development"
        description="The next generation of applications are cloud-native. To succeed in fast-paced, markets, companies must change the way they design, build, and use applications."
        type="website"
        image={headerimg}
      />
      <DevelopmentMain data={data} />
    </Layout>
  );
};

export const query = graphql`
  query allDevelopmentBlogs {
    caseStudies: allSanityCaseStudy(
      filter: { tags: { elemMatch: { name: { eq: "Development" } } } }
      sort: { order: DESC, fields: _createdAt }
      limit: 5
    ) {
      edges {
        node {
          id
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          about {
            _type
            children {
              _key
              _type
              text
            }
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX)
            }
          }
          altMainImage
          caseStudyDescription {
            children {
              text
            }
          }
          title
        }
      }
    }
    cloudInsights: allSanityBlog(
      filter: { blogType: { elemMatch: { title: { eq: "cloud-insights" } } } }
      sort: { order: DESC, fields: publishedAt }
      limit: 5
    ) {
      edges {
        node {
          id
          title
          subtitle
          publishedAt
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX)
            }
          }
          alt
        }
      }
    }
  }
`;

export default Development;
