import React, { FC } from "react";
import { UseWindowSize } from "../hooks/UseWindowSize";
import stageImage from "../../images/shot_01.webp";
import stageImageMobile from "../../images/mobile-images/shot_01_mobile.webp";

import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import {
  ourApproachData,
  TwoColumnBannerData,
  BenefitData,
} from "./DevelopmentMain.helper";
import ContactForm from "../Shared/ContactUiComponents/ContactForm";
import CloudInsightSliderComponent from "../Shared/CloudInsightSlider/CloudInsightSliderComponent";
import TwoColumnBanner from "../Shared/TwoColumnImgTextbanner/TwoColumnBanner";
import CaseStudiesSliderComponent from "../Shared/CaseStudiesSlider/CaseStudiesSliderComponent";
import OurApproachComponent from "../Shared/OurApproachComponent/OurApproachComponent";
import BenefitsComponent from "../Shared/BenefitsComponent/BenefitsComponent";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";
import * as styles from "../CaseStudyUiComponents/CaseStudy.module.scss";
import translate from "../../i18n/translate";

type Props = {
  data: any;
};

const Development: FC<Props> = ({ data }) => {
  const width = UseWindowSize();

  return (
    <div>
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={translate("development-heading")}
          description={translate("development-description")}
          homeBackground={stageImageMobile}
          buttonText={translate("home-contactus")}
          date=""
          link="/contact"
          alt="Cloud Native"
        />
      ) : (
        <HeroBanner
          heading={translate("development-heading")}
          description={translate("development-description")}
          homeBackground={stageImage}
          buttonText={translate("home-contactus")}
          date=""
          link="/contact"
        />
      )}
      <InnerWrapper>
        <div>
          <TwoColumnBanner data={TwoColumnBannerData} headingTop={true} />
        </div>
        <div className={styles.benefit}>
          <BenefitsComponent data={BenefitData} />
        </div>
        <CaseStudiesSliderComponent width={width.width} data={data} />
        <div className={styles.approach}>
          <OurApproachComponent
            ourApproachData={ourApproachData}
            heading={translate("optimization-ourApproach")}
          />
        </div>
        <div className={styles.cloudinside}>
          <CloudInsightSliderComponent />
        </div>
        <div className={styles.contacted}>
          <ContactForm heading={translate("contact-developmentHeading")} />
        </div>
      </InnerWrapper>
    </div>
  );
};

export default Development;
