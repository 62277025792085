import cultureIcon from "../../../static/images/icon/social_media.svg";
import communityIcon from "../../../static/images/icon/community.svg";
import workLikeIcon from "../../../static/images/icon/group_510.svg";
import gdnPromote from "../../images/street.webp";
import flowIcon1 from "../../../static/images/icon/flow_1.svg";
import flowIcon2 from "../../../static/images/icon/flow_2.svg";
import flowIcon3 from "../../../static/images/icon/flow_3.svg";
import flowIcon4 from "../../../static/images/icon/flow_4.svg";
import flowIcon5 from "../../../static/images/icon/flow_5.svg";
import translate from "../../i18n/translate";

export const coreValues = [
  {
    count: "01",
    heading: ".Love what you do",
    description:
      "Talent without passion is like having a Lambda function without any argument.",
  },
  {
    count: "02",
    heading: ".Learn and be curious",
    description:
      "How to get better? Motivation, curiosity, passion and striving for new technologies. That's all you need!.",
  },
  {
    count: "03",
    heading: ".People first",
    description:
      "The team is the most important resource. Care for each other, support each other and have fun together.",
  },
  {
    count: "04",
    heading: ".Trust",
    description:
      "Longstanding partnerships are based on trust, transparency and honesty. This is what we value.",
  },
  {
    count: "05",
    heading: ".Invent & Simplify",
    description:
      "Nothing worse than going out with an over engineered app. We love to invent innovative solutions while keeping things simple and efficient.",
  },
  {
    count: "06",
    heading: ".Crazy Ideas & Creativity",
    description:
      "Run of the mill? Not in our company! We think differently and like crazy ideas, creating something special and unique.",
  },
];

export const yourBenefitData = [
  {
    image: cultureIcon,
    heading: "Culture & Development",
    values: [
      "Open feedback culture & space for innovation",
      "Agile working methods",
      "Training budget & Certification rewards",
      "Flat hierarchies for creative ideas and unconventional approaches",
      "Work with Cutting-Edge Technologies",
      "Fast and clear decision-making processes",
      "Working with friends",
    ],
  },
  {
    image: communityIcon,
    heading: "Community",
    values: [
      "Unforgettable events & parties",
      "weekly Tech & Beer",
      "Top of the line equipment",
      "Monthly choice of gift vouchers",
      "Tank top or Sandals - be yourself",
      "Visit your friends at our different sites",
      "All you can drink & snacks",
    ],
  },
  {
    image: workLikeIcon,
    heading: "Work-Life",
    values: [
      "Life happens, and work can’t always come first. We support you in finding a way to balance the two",
      "Choose your work & office time flexible",
      "30 days vacation",
      "Weekly happy hours",
      "No travelling needed",
      "Dog-friendly office",
    ],
  },
];

export const CareerMainData = {
  heading1: `That's us`,
  paragraph1: `The team is the most important factor. We love what we do and we
  love to build innovative solutions and do things differently than
  others. Working together at globaldatanet means supporting each
  other, having fun together and be unique.`,
  heading2: `.Behind the scenes, the drivers behind`,
  paragraph2: `The story behind our innovative cloud solutions begins with
  our team. We are a team of versatile specialists not
  generalists. Everyone focuses on their core areas building up
  in depth expert knowledge, ensuring highest quality and deep
  expertise in a variety of areas.`,
};

export const coreValuesData = [
  { count: "01", description: "Love what you do" },
  { count: "02", description: "Support each other" },
  { count: "03", description: "Learn and be curious" },
  { count: "04", description: "People first" },
  { count: "05", description: "Trust" },
  { count: "06", description: "Invent &amp; Simplify" },
  { count: "07", description: "Crazy Ideas &amp; Creativity" },
  { count: "08", description: "Make it fun" },
  { count: "09", description: "Community Contribution" },
];

export const inNumbersData = [
  { count: "22", description: "Talents" },
  { count: "3", description: "Locations" },
  { count: "95%", description: "Tech Ratio" },
  { count: "1", description: "APN ambassador" },
  { count: "0", description: "Server" },
  { count: "1", description: "AWS Comunity Builder" },
  { count: "12", description: "Languages" },
  { count: "33", description: "Certifications" },
];

export const ContactFormData = {
  heading: "Let's discuss your new application",
};

export const ourApproachData = [
  {
    count: "01",
    heading: translate("development-ourApproachHeading1"),
    description: translate("development-ourApproachDescription1"),
  },
  {
    count: "02",
    heading: translate("development-ourApproachHeading2"),
    description: translate("development-ourApproachDescription2"),
  },
  {
    count: "03",
    heading: translate("development-ourApproachHeading3"),
    description: translate("development-ourApproachDescription3"),
  },
];

export const TwoColumnBannerData = {
  heading: translate("development-buildCloud"),
  details: translate("development-buildCloudDescription"),
  img: gdnPromote,
  alt: "Road winding through lush green forest for serverless development from anywhere",
};

export const BenefitData = {
  heading: translate("optimization-benefits"),
  benefitsDataMobile: [
    {
      count: "01",
      img: flowIcon3,
      heading: translate("development-benefitsHeading1"),
      description: translate("development-benefitsDescription1"),
      alt: "globaldatanet time clock icon",
    },
    {
      count: "02",
      img: flowIcon1,
      heading: translate("development-benefitsHeading2"),
      description: translate("development-benefitsDescription2"),
      alt: "globaldatanet life cycle release cycle icon",
    },
    {
      count: "03",
      img: flowIcon2,
      heading: translate("development-benefitsHeading3"),
      description: translate("development-benefitsDescription3"),
      alt: "globaldatanet size scalability icon",
    },
    {
      count: "04",
      img: flowIcon5,
      heading: translate("development-benefitsHeading4"),
      description: translate("development-benefitsDescription4"),
      alt: "globaldatanet rising efficiency icon",
    },
    {
      count: "05",
      img: flowIcon4,
      heading: translate("development-benefitsHeading5"),
      description: translate("development-benefitsDescription5"),
      alt: "globaldatanet flexible agility icon",
    },
  ],
};
